export class User {
    id: string = '';
    username: string = '';
    email: string = '';
    firstname: string = '';
    lastname: string = '';
    gaveTheirInfos: boolean = false;
    alreadyRenewed: boolean = false;
    lastAcceptedCguVersion: string = '';
    lastCguVersion: string = '';
    expiry: any = null;
    token: string = '';
    refresh_token: string = '';
}