import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { first, catchError, delay } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { SettingsService } from '@app/_services/settings.service';

declare let window: any;

@Component({
  templateUrl: './renewal.component.html',
})
export class RenewalComponent implements OnInit {
  form: FormGroup;
  formPayment:any = null;
  loading = false;
  submitted = false;
  env = environment;
  availableModels = <any>[];
  availableDealerships = <any>[];
  checking = true;
  alreadyRenewed = false;
  tokens:any;
  userInfos: any = null;
  currentAmount = 0;
  reductAmount = 0;
  appSettings: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private settingsService: SettingsService
  ) {
  	this.tokens = this.accountService.tokens;
    this.form = this.formBuilder.group({
        code: [''],
        lastname: ['', Validators.required],
        firstname: ['', Validators.required],
        company: [''],
        address: ['', Validators.required],
        addresscpt1: [''],
        addresscpt2: [''],
        postcode: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', Validators.required],
        acceptance: ['', Validators.required]
    });
    
    this.settingsService.settings.subscribe(x => { 
      this.appSettings = x;
    });
  }
  
  ngOnInit() {
	  // On revérifie les infos de l'utilisateur
	  this.refreshUserToken();
  }
  
  refreshUserToken() {
	  this.accountService.getInfos().subscribe((data:any) => {
		  let tokens = this.accountService.tokens;
		  
		  this.currentAmount = this.tokens.cotisation;
		  this.reductAmount = this.tokens.cotisation;
		  
		  tokens.expiry = new Date(data.expiry);
		  this.alreadyRenewed = data.alreadyRenewed;
		  this.accountService.saveToken( tokens );
		  
		  this.userInfos = data;
		  
      this.f['lastname'].setValue(data.lastname);
      this.f['firstname'].setValue(data.firstname);
      this.f['company'].setValue(data.company);
      this.f['address'].setValue(data.address);
      this.f['addresscpt1'].setValue(data.addresscpt1);
      this.f['addresscpt2'].setValue(data.addresscpt2);
      this.f['postcode'].setValue(data.postcode);
      this.f['city'].setValue(data.city);
      this.f['country'].setValue(data.country);
      
      // On autorise pas l'utilisateur à renouveler si son expiration n'est pas en desous de 31 jours ( un e-mail de rappel est envoyé à 30jours)
      let diffDate = new Date( data.expiry );
      diffDate.setDate(diffDate.getDate() - 31);
      //var dateString = diffDate.toISOString().split('T')[0]; // "2016-06-08"
		  if( diffDate >= new Date() )
		  {
			  this.router.navigate(['']);
		  }
		  
		  this.checking = false;
	  });
  }

  //convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  
  onSubmit() {
      this.submitted = true;
      
      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }

      this.loading = true;
      this.accountService.sendRenewal( this.form.value )
        .pipe(first())
        .subscribe(
          (data:any) => {
            
            if( data ) {
	            this.alertService.success('Vous allez être redirigé vers notre prestataire de paiement');
	            
	            this.formPayment = data;
	            
	            let url = this.mercanetHtmlToUrl(data.redirectionUrl, data.redirectionVersion, data.redirectionData);
	            window.setTimeout(() => {
	              // On traite le formulaire différemment si on est dans l'app mobile ou sur navigateur
	              if( window.cordova ) {
	                let inAppBrowserRef = window.cordova.InAppBrowser.open(url, '_blank', 'location=yes,hidden=no');
	                
	                inAppBrowserRef.addEventListener('exit', () => {
	                	window.document.location.href = "#/";
	                });
	                
	                // On détecte la redirection et on quitte le inAppBrowser
	                inAppBrowserRef.addEventListener('loadstart', (params:any) => {
	                	console.log(params.url)
	                	if (params.url.includes("my-account/renew")) {
	                      // On ferme la webview 
	                      inAppBrowserRef.close();
	                  }
	                });
	              } else {
		            let document: any = window.document;
	                document.redirectForm.submit();
	              }
	            }, 2000);
            }
            else
            {
            	window.location.reload();
            }
          },
          err => {
              this.alertService.error('Une erreur est survenue');
              
              this.loading = false;
              
              // hightlight des erreurs
              for( let i in err.errors )
              {
                let error = {};
                Object.defineProperty( error, err.errors[i].message, {
                	  value: true,
                	  writable: false
                } );
                
                this.f[err.errors[i].property].setErrors( error );
              }
              
          }
        );
  }
  
  mercanetHtmlToUrl(url:any, version:any, data:any) {
    let HTML =
      '<form method="post" id="mercanetForm" action="' +
      url +
      '">' +
      '<input type="hidden" name="redirectionVersion" value="' +
      version +
      '">' +
      '<input type="hidden" name="redirectionData" value="' +
      data +
      '">' +
      '</form>' +
      '<script type="text/javascript">document.getElementById("mercanetForm").submit();</script>'

    return 'data:text/html;base64,' + btoa(HTML)
  }
  
  validCoupon() {
  	let code = this.f['code'].value;
  	
  	if( code )
  	{
	  	this.accountService.checkCoupon(code, this.currentAmount).subscribe((res: any) => {
	  		if( res.exists )
	  		{
	  			this.reductAmount = res.amount;
	  		}
	  		else
	  		{
	  			this.f['code'].setValue('');
		  		this.alertService.error('Coupon inexistant');
	  		}
	  	}, () => {
	  		this.alertService.error('Erreur de validation du coupon');
	  	});
  	}
  	
  }
  
  changeCoupon() {
  	this.reductAmount = this.currentAmount; // Si on change le code du coupon, on reset le prix
  }
  
  logout() {
  	this.accountService.logout();
  }
}
